<template>
  <div class="center">
    <h1 class="center-title">我是个人中心页面我是个人中心页面我是个人中心页面我是个人中心页面我是个人中心页面我是个人中心页面我是个人中心页面</h1>
    <HelloWorld />
    <el-row>
      <el-button @click="handleLinkUserList">跳转用户列表</el-button>
      <el-button type="success" @click="handleGetBanner">获取接口</el-button>
      <el-button type="info" @click="handleLinkBack">回退按钮</el-button>
      <el-button type="warning">警告按钮</el-button>
      <el-button type="danger">危险按钮</el-button>
    </el-row>

    <el-checkbox-group v-model="checkList">
      <el-checkbox label="复选框 A"></el-checkbox>
      <el-checkbox label="复选框 B"></el-checkbox>
      <el-checkbox label="复选框 C"></el-checkbox>
      <el-checkbox label="禁用" disabled></el-checkbox>
      <el-checkbox label="选中且禁用" disabled></el-checkbox>
    </el-checkbox-group>

    <el-icon>
      <Edit />
    </el-icon>

    <div style="height: 1300px;"></div>
  </div>
</template>

<script setup>
import { reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'
import HelloWorld from '@/components/HelloWorld.vue'
import { getBannerList } from '@/api'

const state = reactive({
  checkList: ['选中且禁用', '复选框 A']
})
const { checkList } = toRefs(state)

const router = useRouter()

function handleLinkUserList () {
  router.push({
    path: '/userList',
    query: {
      id: '123'
    }
  })
}
function handleGetBanner () {
  getBannerList({}, { header: { a: 1 } }).then(res => {
    console.log('哈哈哈', res)
  })
}
function handleLinkBack () {
  router.back()
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins.scss";
.center-title {
  width: 200px;
  @include lineClamp(1);
}
</style>
